.file-input {
  display: flex;
  align-items: center;
  .file-input-preview {
    margin-right: 10px;
    .file-input-preview-card {
      padding: 8px;
      border: 1px solid #e6ebf1;
      border-radius: 0.625rem;
      video {
        height: 200px;
        width: 300px;
      }
      img {
        max-height: 200px;
        max-width: 200px;
      }
    }
  }
}

.app-header {
  right: 0;
  left: unset;
  transition: all 0.2s ease;
  .nav {
    width: 100%;
  }
}

.app-button {
  background: transparent;
  border: none;
  padding: 0;
}

#picturesList {
  .card-cover {
    display: flex;
    aspect-ratio: 1.5;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .card-actions {
    text-align: center;
    margin-top: 15px;
  }

  .drop-over-downward {
    border-right: 2px dashed #1890ff;
  }

  .drop-over-upward {
    border-left: 2px dashed #1890ff;
  }
  .upload-card {
    display: flex;
    height: calc(100% - 20px);
    align-items: center;
    justify-content: center;
  }
}
